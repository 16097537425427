<template>
  <div>
    <div class="container-wrap">
      <div class="title">
        <div>在设置的开始时间至结束时间范围内，群成员可以直接将其他成员邀请进群；</div>
        <div style="color:red">其余时间，需要群主或者管理确认才能邀请成员进群，且扫描二维码进群的功能将同时停用</div>
      </div>
      <div class="content">
        <div>
          是否开启：<el-switch
            v-model="setting"
            active-color="#13ce66"
            @change="changeAuth"
          ></el-switch>
        </div>
        <div v-if="setting == true">
          <div class="item">
            <div>开始时间：</div>
            <el-select v-model="starttime" placeholder="请选择">
              <el-option
                v-for="item in timeList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="space">时</div>
            <div style="margin: 0 20px">~</div>
            <div>结束时间：</div>
            <el-select v-model="endtime" placeholder="请选择">
              <el-option
                v-for="item in timeList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="space">时</div>
          </div>
          <div class="item">
            <div><span style="color: red">排除</span>的群:</div>
            <el-button
              type="primary"
              icon="el-icon-plus"
              style="margin-left: 10px"
              @click="groupAction"
              >选择群</el-button
            >
            <span
              style="margin-left:5px;">已选择(<span style="color: #BFB18A">{{groupList.length||0}}</span>)个群</span
            >
          </div>
          <div style="margin-top:20px;margin-left:70px">
            <el-button type="primary" @click="confirmAction">保 存</el-button>
            <!-- <el-button plain @click="resetAction">重置</el-button> -->
          </div>
        </div>
      </div>
    </div>
    <GroupSelectCop ref="groupSelectDialog" @onConfirm="chooseGroupAction"/>
  </div>
  
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import GroupSelectCop from '@/components/GroupSelectCop'
import { ElMessageBox, ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import {GetAutoAuthHour,SetAutoAuthHour,SwitchAutoAuth} from "@/helper/group.js"
export default {
  components: {
    GroupSelectCop
  },
  setup() {
    const router = useRouter();
    const isFirst = ref(true);
    const setting = ref(0);
    const groupSelectDialog = ref(null);
    const starttime = ref("未设置");
    const endtime = ref("未设置");
    const timeList = ref([
      "未设置",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ]);
    onMounted(()=>{
      GetAutoAuthHour().then(res=>{
        setting.value = res.Switch==1?true:false,
        starttime.value = res.StartHour==-1?"未设置": res.StartHour,
        endtime.value = res.EndHour==-1?"未设置": res.EndHour,
        groupList.value = res.ExcludedGroups||[]
        isFirst.value = false
      })
    })

    /***************群事件*****************/
    const groupList = ref([])
    //选择群点击
    function groupAction(){
      groupSelectDialog.value.initCop(0,groupList.value);
    }

    //确认群
    function chooseGroupAction(arr){
      groupList.value = arr;
    }

     /***************点击事件*****************/
    // 设置开启群聊邀请设置
    function changeAuth(val){
      if(isFirst.value){
        return
      }

      if (val) {
        setting.value = val
        return
      }

      ElMessageBox.confirm("确认要关闭群聊邀请吗？", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
          SwitchAutoAuth().then(res=>{
            setting.value = val
            ElMessage({
              type: "success",
              message:"关闭成功！",
            });
          })
        }).catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
          setting.value = true
        });
    }

    //确认
    function confirmAction(){
      let param = {
        StartHour:starttime.value,
        EndHour:endtime.value,
        ExcludedGroups:groupList.value.map(item=>item.GroupID).join(",")
      }
      SetAutoAuthHour(param).then(res=>{
        ElMessage({
            type: 'success',
            message: '设置成功',
        });
      })
      
    }

    //重置
    function resetAction(){
        ElMessage({
              type: 'success',
              message: '重置成功',
          });
    }


    return {
      setting,
      starttime,
      timeList,
      endtime,
      groupSelectDialog,
      groupAction,
      chooseGroupAction,
      groupList,
      resetAction,
      confirmAction,
      changeAuth,
      isFirst
    };
  },
};
</script>

<style lang="scss" scoped>
.container-wrap {
  background-color: #fff;
  //   padding: 20px;
  box-sizing: border-box;
  .title {
    font-size: 14px;
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .content {
    font-size: 14px;
    padding: 20px;
    .item {
      display: flex;
      align-items: center;
      margin-top: 20px;
      // margin-left: 15px;
      .space{
        margin-left: 5px;
      }
    }
  }
}
:deep().item .el-input {
  width: 100px;
}
</style>